:root {
  --backgorund-color: #01c897;
  --dark-color: #141414;
  --ag-active-color: red;
}
#user-detail-panel-2 {
  height: 100vh !important;
}
.card-text {
  margin-bottom: 0;
}
#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: "";
}
.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}
.summernote > :first-child {
  min-height: 250px;
}
.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

/* Picker */
.slick-slider .items {
  padding: 0.5rem;
}
.slick-next:before,
.slick-prev:before {
  line-height: 1;
  opacity: 0.75;
  color: #6cc51d;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -14px;
  right: 30px;
  font-size: 32px;
}
.slick-next::after,
.slick-prev::after {
  line-height: 1;
  opacity: 0.75;
  color: #6cc51d;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -14px;
  right: 30px;
  font-size: 32px;
  z-index: 99;
}
.items {
  padding-right: 25px;
}

.react-calendar {
  border: none;
}
.icon-false:after {
  content: none !important;
}

.swal-footer {
  text-align: center !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* Height 80 */
.h-80 {
  min-height: 80vh;
}
.h-70 {
  min-height: 70vh;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato");
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}
.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}
.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}
.range-picker-input * {
  box-sizing: border-box;
}
.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}
.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}
.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}
.picker-input {
  display: inline-block;
  position: relative;
}
.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}
.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}
.picker-input__text:disabled {
  background: #e4e4e4;
}
.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.picker {
  display: inline-block;
}
.picker__container {
  position: absolute;
  z-index: 100;
}
.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.picker__container__include-time {
  border: 1px solid #e4e4e4;
}
.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}
.picker__container__tab {
  margin: 10px 0;
}
.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}
.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}
.picker__container__tab button:first-child {
  border-right: none;
}
.picker__container__tab button svg {
  margin-right: 5px;
}
.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}
.time__container__div {
  margin: 0 10px;
}
.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}
.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}
.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}
.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}
.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}
.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}
.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}
.calendar__list {
  display: table;
}
.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}
.calendar__item:not(:first-child) {
  border-left: none !important;
}
.calendar--show {
  display: inline-block;
}
.calendar__head {
  position: relative;
  background: #7986cb;
  padding: 10px 6px;
}
.calendar__head--title {
  font-size: 1.3em;
  color: white;
  text-align: center;
  margin: 4px;
}
.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}
.calendar__head--button svg {
  fill: white;
}
.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.calendar__head--prev {
  left: 0;
}
.calendar__head--next {
  right: 0;
}
.calendar__panel--show {
  display: block !important;
}
.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}
.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}
.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #212121;
}
.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}
.calendar__day self:hover:not(&--disabled) {
  background: #aab6fe;
  cursor: pointer;
}
.calendar__day--0 {
  color: red;
}
.calendar__day--6 {
  color: blue;
}
.calendar__day--today {
  background: #fff9c4;
}
.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}
.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}
.calendar__day--range {
  background: #dce1ff;
}
.calendar__day--text {
  display: block;
  font-size: 10px;
}
.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}
.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

.cd-brand-logo-wp {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.cd-brand-logo {
  height: 50%;
  cursor: pointer;
}
.nav-text {
  color: white;
}
.active-sidebar-item {
  background-color: var(--backgorund-color);
}
.ag-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
}

.ag-search-input {
  width: 200px !important;
}

.custom-spinner-wp {
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom-spinner {
  background-color: var(--backgorund-color);
}

.detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-header button {
  background-color: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

#expert-profile {
  width: 100%;
  height: 100px;
  object-fit: contain;
  border: 1px solid var(--backgorund-color);
  border-radius: 100%;
}
.ant-dropdown-menu-item {
  padding: 0px !important;
}
.ant-spin-dot-item {
  background-color: white !important;
}
.sesion-rounded {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: rgba(128, 128, 128, 0.108);
  border: 1px solid var(--backgorund-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-all;
}

.ant-checkbox-wrapper {
  width: 24%;
  margin-bottom: 0.1rem;
  display: flex;
  align-items: "center";
}
.ant-checkbox-wrapper-in-form-item,
.ant-checkbox-group-item {
  align-items: center;
}
.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: red;
  z-index: 2;
  padding-left: 21.563rem;
}
@media only screen and (max-width: 1400px) {
  .action-bottom {
    padding-left: 18rem;
  }
}

[data-sidebar-style="overlay"] .action-bottom {
  padding-left: 0 !important ;
}
.ag-watermark {
  visibility: hidden !important;
}
.ant-btn-text {
  border-bottom: 1px solid var(--backgorund-color) !important;
}
[data-sidebar-style="overlay"] .cd-brand-logo-wp,
[data-sidebar-style="mini"] .cd-brand-logo-wp {
  justify-content: center;
  align-items: center;
}
.ant-popconfirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cd-brand-logo-passive {
  height: 20%;
}
.metismenu {
  padding-top: 1px !important;
}

[data-sidebar-style="mini"] .action-bottom {
  padding-left: 5rem;
}
.ai-icon {
  display: flex !important;
  align-items: center;
}

.deznav,
.nav-header {
  z-index: 2001 !important;
}
.header {
  z-index: 1001 !important;
}
.ant-modal-mask {
  z-index: 3000 !important;
}
.ant-modal-wrap {
  z-index: 3001 !important;
}
.ant-picker-dropdown {
  z-index: 3002 !important;
}

.st0 {
  display: none;
}
.st1 {
  display: inline;
}
.st2 {
  opacity: 0.2;
  fill: none;
  stroke: #000000;
  stroke-width: 5e-2;
  stroke-miterlimit: 10;
}

.expert-slot-item-active {
  background-color: #01c89678;
}
.expert-slot-item-passive {
  background-color: rgba(239, 90, 90, 0.646);
}
.content-body {
  height: 100vh;
}
.container-fluid {
  overflow-y: scroll;
  height: 100%;
}
[data-theme="dark"] {
  background-color: var(--dark-color) !important;
}
.deznav {
  border-right: 1px solid #7ce7c0 !important;
}
.bg-purple {
  background-color: #8604a7a8;
}
.bg-yellow {
  background-color: #f3e416a8;
}
.deznav-scroll {
  overflow-y: scroll !important;
}
.deznav-scroll::-webkit-scrollbar {
  width: 5px;
}
.deznav-scroll::-webkit-scrollbar-thumb {
  background: #9a9999;
}
.deznav-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.codex-editor {
  border: 5px solid var(--backgorund-color);
  border-bottom-width: 0px;
  padding: 2rem;
}
#embed-button {
  background-color: var(--backgorund-color) !important;
  border-radius: none !important;
}
#inline-image__modal {
  z-index: 3500;
  margin-top: 5rem;
  padding-top: 2rem;
  height: 50% !important;
  width: 50% !important;
}
#inline-image__modal span {
  background-color: var(--backgorund-color) !important;
}
.ce-toolbar__actions {
  background-color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
[id^="react-editor-js"] {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
}
.inline-image__tab:nth-child(2) {
  display: none !important;
}
.codex-editor__redactor {
  margin-right: 0px !important;
  padding-bottom: 0px !important;
}
.minimal-toolbar_container__Rz_Iq {
  display: none !important;
}
.ct--bottom {
  z-index: 3500 !important;
}
[data-placeholder="Enter a caption"] {
  display: none !important;
}

.ag-theme-quartz {
  --ag-active-color: var(--backgorund-color) !important;
}
.mask-antdinput:hover {
  border-color: var(--backgorund-color);
}
.mask-antdinput:focus {
  border-color: var(--backgorund-color);
}
.mask-antdinput {
  border-radius: 0px;
}
.footer-editorjs {
  position: sticky;
  bottom: 0;
  background-color: white;
  width: 100%;
  z-index: 3500 !important;
}
@media only screen and (max-width: 1300px) {
  .cd-brand-logo {
    height: 20%;
  }
}
@media only screen and (max-width: 700px) {
  .ag-header {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .ag-header-children {
    margin-top: 1rem;
  }
  .metismenu li {
    margin-bottom: 0.5rem;
  }
  .metismenu li a {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 800px) {
  .sesion-rounded {
    width: 50px;
    height: 50px;
  }
}
